<template>
    <div class="btn-group">
        <button
            type="button"
            class="btn btn-light"
            @click="callback(1)"
            :class="{ active: selected == 1 }"
        >1
        </button>

        <button
            type="button"
            class="btn btn-light"
            v-for="(el, i) in pages"
            :key="i"
            @click="callback(el)"
            :class="{ active: selected == el }"
        >{{ el }}
        </button>

        <button
            v-if="total != 1"
            type="button"
            class="btn btn-light"
            @click="callback(total)"
            :class="{ active: selected == total }"
        >{{ total }}
        </button>
    </div>
</template>

<script>
export default {
    name: "App-Paginate",
    props: ["total", "selected", "callback"],
    data() {
        return {};
    },
    computed: {
        pages: function () {
            var result = [];

            let pageStart = this.selected - 2;
            if (pageStart <= 1) {
                pageStart = 2;
            }
            let pageEnd = this.selected + 2;
            if (pageEnd >= this.total) {
                pageEnd = this.total - 1;
            }

            for (let i = pageStart; i <= pageEnd; i++) {
                result.push(i);
            }

            return result;
        },
    },
    created() {
    },
    unmounted() {
    },
};
</script>

<style scoped lang="scss">
</style>