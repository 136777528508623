<template>
  <div>
    <div class="container">
      <div class="row">
        <h2 class="col-12 my-3">Теги</h2>

        <div class="col-8">
          <div class="input-group mb-3">
            <input type="text" class="form-control" placeholder="Поиск" v-model="query"/>
          </div>
        </div>
      </div>

      <br/>

      <div class="btn-toolbar" role="toolbar">
        <router-link to="/tag-create" class="action">
          <button type="button" class="btn btn-primary mr-3">
            <i class="fas fa-plus-square"></i> Создать
          </button>
        </router-link>
      </div>

      <div class="my-4">
        <span>По запросу найдено {{ tags.length }} тегов</span>
      </div>

      <br/>

      <div>
        <span class="tag" v-for="(tag, i) in tags" :key="i">
          {{ tag.Name }} ({{ tag.PagesCount }})
          <span>
            <router-link :to="{ name: 'tag-edit', params: { id: tag.Id }}" class="action">
              <i class="fal fa-pencil"></i>
            </router-link>
          </span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import {TagSearch} from "@/api";

export default {
  name: "Tag-List",
  components: {},
  data: function () {
    return {
      query: "",

      tags: [],
    };
  },
  watch: {
    query: function () {
      this.debounceSearch();
    }
  },
  created() {
    this.search();
  },
  computed: {
  },
  methods: {
    debounceSearch: function () {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.search();
      }, 200);
    },
    search: function () {
      TagSearch(this.query)
      .then((data) => {
        this.tags = data.Document;
      })
      .catch((error) => console.error(error));
    },
  },
};
</script>

<style scoped lang="scss">
.tag {
  border: 1px solid black;
  border-radius: 5px;
  padding: 10px 15px;
  margin: 5px 10px 0 0;
  font-size: 20px;
  line-height: 20px;

  display: inline-flex;

  & > span {
    margin: 0 0 0 5px;
    font-size: 15px;

    &:hover {
      color: red;
    }
  }
}
</style>