<template>
  <div>
    <div class="container">
      <div class="row">
        <h2 class="col-12 my-3">Изменение медиа</h2>

        <div class="col-12 my-2">
          <div>
            <span>Идентификатор: {{ media.ShortId }}</span>
          </div>
        </div>

        <div class="col-8">
          <div class="form-group">
            <label for="article-title">Описание</label>
            <textarea
                class="form-control form-control-lg"
                id="article-title"
                rows="5"
                v-model="description"
                maxlength="256"
            ></textarea>
          </div>
        </div>

        <!-- <div class="col-8">
          <div class="input-file">
            <label class="label">
              <i class="fal fa-paperclip"></i>
              <span class="title">Загрузить файл</span>
              <input type="file" ref="file" v-on:change="handleFileUpload()" />
            </label>
          </div>
        </div> -->

<!--        <div class="col-6 my-2">-->
<!--          <img :src="filePreview" class="image-preview"/>-->
<!--        </div>-->

        <div class="col-6 my-2">
          <img :src="filePreview" class="image-preview"/>
        </div>

        <div class="col-12">
          <button type="button" class="btn btn-primary" @click="update">Сохранить</button>
        </div>

        <div class="col-12 my-5">
          <h4>Список страниц использующих это изображение как главное</h4>

          <div class="my-2">
            <span>По запросу найдено {{ mainImageTotal }} страниц</span>
          </div>

          <Paginate class="my-3" :total="mainImagePageMax" :selected="mainImagePage" :callback="mainImagePageSelect" v-show="mainImagePageMax > 1"/>

          <div class="page" v-for="(page, i) in mainImagePages" :key="i">
            <span>{{ page.Id }} | </span>
            <span>{{ page.Title }}  </span>
            <router-link :to="{ name: 'article-edit', params: { id: page.Id }}" class="action">
              <i class="fal fa-pencil"></i>
            </router-link>
          </div>
        </div>

        <div class="col-12 my-5">
          <h4>Список страниц использующих это изображение в тексте страницы</h4>

          <div class="my-2">
            <span>По запросу найдено {{ inBodyTotal }} страниц</span>
          </div>

          <Paginate class="my-3" :total="inBodyPageMax" :selected="inBodyPage" :callback="inBodyPageSelect" v-show="inBodyPageMax > 1"/>

          <div class="page" v-for="(page, i) in inBodyPages" :key="i">
            <span>{{ page.Id }} | </span>
            <span>{{ page.Title }}  </span>
            <router-link :to="{ name: 'article-edit', params: { id: page.Id }}" class="action">
              <i class="fal fa-pencil"></i>
            </router-link>
          </div>
        </div>

        <div class="col-12 mt-5 mb-5" v-show="inBodyTotal === 0 && mainImageTotal === 0">
          <button type="button" class="btn btn-primary" @click="remove">Удалить медиа</button>
          <div class="alert alert-danger col-6 my-2" role="alert">
            После нажатия на кнопку этот медиа-ресурс будет <span class="alert-link">удалён</span>.
            Это действие нельзя отменить.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {MediaGet, MediaUpdate, PageGetAllByMainImageId, PageGetAllWithImage, MediaRemove} from "@/api";
import Paginate from "@/components/Paginate";

export default {
  name: "Media-Edit",
  components: {
    Paginate,
  },
  props: ["id"],
  data: function () {
    return {
      description: "",
      file: "",

      media: {},

      filePreview: null,

      itemsOnPage: 25,

      mainImagePage: 1,
      mainImagePageMax: 1,
      mainImagePages: [],
      mainImageTotal: -1,

      inBodyPage: 1,
      inBodyPageMax: 1,
      inBodyPages: [],
      inBodyTotal: -1,
    };
  },
  computed: {},
  watch: {
    mainImagePage: function () {
      this.pageGetAllByMainImageId();
    },
    inBodyPage: function () {
      this.pageGetAllWithImage();
    },
  },
  created() {
    this.get();
    this.pageGetAllByMainImageId();
    this.pageGetAllWithImage();
  },
  methods: {
    mainImagePageSelect(page) {
      this.mainImagePage = Number.parseInt(page);
    },
    inBodyPageSelect(page) {
      this.inBodyPage = Number.parseInt(page);
    },
    handleFileUpload() {
      this.file = this.$refs.file.files[0];

      this.filePreview = URL.createObjectURL(this.file);
    },
    update: function () {
      MediaUpdate(this.id, this.description)
      .then((data) => {
        if (data.IsSuccess) {
          this.$router.push({name: 'media-list'});
        }
        // console.log(data);
        // if (!data.is_successed) {
        //   Store.commit("ErrorAdd", data.error);
        //   return;
        // }
        // this.rowsTable = data.document;
      })
      .catch((error) => console.error(error));
    },
    get: function () {
      // console.log("get", this.id);
      MediaGet(this.id)
      .then((data) => {
        this.media = data.Document;
        this.description = this.media.Description;
        this.filePreview = this.media.Link;
        // if (data.IsSuccess) {
        // this.$router.push("media-list");
        // }
        // console.log(data);
        // if (!data.is_successed) {
        //   Store.commit("ErrorAdd", data.error);
        //   return;
        // }
        // this.rowsTable = data.document;
      })
      .catch((error) => console.error(error));
    },
    pageGetAllByMainImageId: function () {
      PageGetAllByMainImageId(this.id, this.mainImagePage, this.itemsOnPage)
      .then((data) => {
        if (data.IsSuccess) {
          this.mainImagePages = data.Document.Items;
          this.mainImageTotal = data.Document.Total;
          this.mainImagePageMax = Math.floor(this.mainImageTotal / this.itemsOnPage) + 1;
        }
      })
      .catch((error) => console.error(error));
    },
    pageGetAllWithImage: function () {
      PageGetAllWithImage(this.id, this.inBodyPage, this.itemsOnPage)
      .then((data) => {
        if (data.IsSuccess) {
          this.inBodyPages = data.Document.Items;
          this.inBodyTotal = data.Document.Total;
          this.inBodyPageMax = Math.floor(this.inBodyTotal / this.itemsOnPage) + 1;
        }
      })
      .catch((error) => console.error(error));
    },
    remove: function () {
      MediaRemove(this.id)
      .then((data) => {
        if (data.IsSuccess) {
          this.$router.push({name: 'media-list'});
        }
      })
      .catch((error) => console.error(error));
    }
  },
};
</script>

<style scoped lang="scss">
.image-preview {
  max-width: 300px;
  max-height: 300px;
}

.input-file {
  input[type="file"] {
    display: none;
  }

  .label {
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    border: 2px dashed grey;
    border-radius: 5px;
    padding: 1.2em;
    font-size: 20px;
    transition: border 300ms ease;
    cursor: pointer;
    text-align: center;

    &:hover {
      border: 2px solid #000;

      & > svg,
      & > .title {
        color: black;
      }
    }

    i {
      margin: 0 10px;
    }

    .title {
      color: grey;
      transition: 200ms color;
    }
  }
}
</style>