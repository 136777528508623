<template>
  <div>
    <div class="container">
      <div class="row">
        <h2 class="col-12 my-3">Создание блока аналитики</h2>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label for="advert-name">Имя</label>
            <input type="text" class="form-control" id="advert-name" v-model="name"/>
          </div>
        </div>

        <div class="col-12">
          <div class="form-group">
            <label for="advert-script">Скрипт</label>
            <textarea
                class="form-control form-control-lg"
                id="advert-script"
                rows="3"
                v-model="script"
            ></textarea>
          </div>
        </div>

        <div class="col-12 my-3">
          <button type="button" class="btn btn-primary" @click="create">Создать</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {AnalyticsCreate} from "@/api";

export default {
  name: "Analytics-Create",
  components: {},
  data: function () {
    return {
      name: "",
      script: "",
    };
  },
  watch: {},
  created() {},
  computed: {},
  methods: {
    create: function () {
      AnalyticsCreate(this.name, this.script)
      .then((data) => {
        if (data.IsSuccess) {
          this.$router.push({name: 'analytics-list'});
        }
      })
      .catch((error) => console.error(error));
    },
  },
};
</script>

<style scoped lang="scss">
.image-preview {
  max-width: 300px;
  max-height: 300px;
}

.tag {
  border: 1px solid var(--gray);
  border-radius: 5px;
  padding: 5px 10px;
  margin: 5px 10px 0 0;

  display: inline-flex;

  & > span {
    margin: 0 5px;

    &:hover {
      color: red;
    }
  }
}
</style>