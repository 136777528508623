<template>
  <div>
    <div class="container">
      <div class="row">
        <h2 class="col-12 my-3">Редактирование тега</h2>
      </div>

      <div class="row">
        <div class="col-8">
          <div class="form-group">
            <label for="article-title">Имя</label>
            <input type="text" class="form-control" id="article-title" v-model="name"/>
          </div>
        </div>

        <div class="col-12 mt-3">
          <button type="button" class="btn btn-primary" @click="save">Сохранить</button>
        </div>

        <br>

        <div class="col-12 my-5">
          <h4>Список страниц в этой категории</h4>

          <div class="my-2">
            <span>По запросу найдено {{ total }} страниц</span>
          </div>

          <Paginate class="my-3" :total="pageMax" :selected="page" :callback="pageSelect"/>

          <div class="page" v-for="(page, i) in pages" :key="i">
            <span>{{ page.Id }} | </span>
            <span>{{ page.Title }}  </span>
            <router-link :to="{ name: 'article-edit', params: { id: page.Id }}" class="action">
              <i class="fal fa-pencil"></i>
            </router-link>
          </div>
        </div>

        <div class="col-12 mt-5 mb-5">
          <button type="button" class="btn btn-primary" @click="remove">Удалить тег</button>
          <div class="alert alert-danger col-6 my-2" role="alert">
            После нажатия на кнопку этот тег будет <span class="alert-link">удалён</span>.
            Это так же удалит этот тег из <span class="alert-link">всех страниц</span> которые использовали этот тег.
            Это действие нельзя отменить.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {PageGetAllByTagId, TagGet, TagUpdate, TagRemove} from "@/api";
import Paginate from "@/components/Paginate";

export default {
  name: "Tag-Edit",
  components: {
    Paginate,
  },
  props: ["id"],
  data: function () {
    return {
      name: "",

      itemsOnPage: 25,
      page: 1,
      pageMax: 1,

      pages: [],
      total: 0,
    };
  },
  watch: {
    page: function () {
      this.pageGetAllByTagId();
    },
  },
  created() {
    this.get();
    this.pageGetAllByTagId();
  },
  computed: {},
  methods: {
    pageSelect(page) {
      this.page = Number.parseInt(page);
    },
    get: function () {
      TagGet(this.id)
      .then((data) => {
        this.name = data.Document.Name;
      })
      .catch((error) => console.error(error));
    },
    save: function () {
      TagUpdate(this.id, this.name)
      .then((data) => {
        if (data.IsSuccess) {
          this.$router.push({name: 'tag-list'});
        }
      })
      .catch((error) => console.error(error));
    },
    remove: function () {
      TagRemove(this.id)
      .then((data) => {
        if (data.IsSuccess) {
          this.$router.push({name: 'tag-list'});
        }
      })
      .catch((error) => console.error(error));
    },
    pageGetAllByTagId: function () {
      PageGetAllByTagId(this.id, this.page, this.itemsOnPage)
      .then((data) => {
        if (data.IsSuccess) {
          this.pages = data.Document.Items;
          this.total = data.Document.Total;
          this.pageMax = Math.floor(this.total / this.itemsOnPage) + 1;
        }
      })
      .catch((error) => console.error(error));
    }
  },
};
</script>

<style scoped lang="scss">
.image-preview {
  max-width: 300px;
  max-height: 300px;
}

.tag {
  border: 1px solid var(--gray);
  border-radius: 5px;
  padding: 5px 10px;
  margin: 5px 10px 0 0;

  display: inline-flex;

  & > span {
    margin: 0 5px;

    &:hover {
      color: red;
    }
  }
}
</style>