<template>
  <div class="menu">
    <div class="menu-left">
      <div class="element">
        <a href="#">
          <i class="fa fa-diamond fa-lg"></i>
          <!-- <span>Аккаунт</span> -->
        </a>
      </div>
      <div class="element">
        <a href="https://metrika.yandex.ru/dashboard?group=day&period=month&id=66994522">
          <i class="fas fa-analytics"></i>
          <span>Яндекс Метрика</span>
        </a>
      </div>
      <div class="element">
        <a href="https://webmaster.yandex.ru/site/https:iz-article.ru:443/dashboard/">
          <i class="fab fa-yandex"></i>
          <span>Яндекс Вебмастер</span>
        </a>
      </div>
    </div>
    <div class="menu-right">
      <div class="element">
        <a href="https://account.dumk.in/">
          <i class="fa fa-user fa-lg"></i>
          <span>Аккаунт</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Menu-Top",
  props: {},
};
</script>

<style scoped lang="scss">
.menu {
  background: #23282d;
  font: 400 13px/32px "Open Sans", sans-serif;
  color: #ccc;

  .element {
    height: 40px;
    display: inline-block;

    & > a {
      line-height: 40px;
      color: #eee;
      padding: 0 8px;
      display: block;
      text-decoration: none;

      &:hover {
        color: #00b9eb;
        background: #32373c;
      }

      & > i {
        margin: 0 5px;
      }
    }
  }

  & > .menu-right {
    float: right;
  }

  & > .menu-left {
    float: left;
  }
}
</style>