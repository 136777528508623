<template>
    <div class="menu">
        <ul>
            <li>
                <router-link to="/" class="action">
                    <i class="fas fa-home"></i>
                    <span>Главная</span>
                </router-link>
            </li>
            <!-- <li>
              <router-link to="/home" class="action">
                <i class="fa fa-home"></i>
                <span>Главная</span>
              </router-link>
            </li>
            <li>
              <a href="index.php?module=user_list">
                <i class="fa fa-user"></i>
                <span>Пользователи</span>
              </a>
              <div class="sub">
                <ul>
                  <li>
                    <a href="index.php?module=user_list">Список</a>
                  </li>
                  <li>
                    <a href="index.php?module=user_create">Добавить</a>
                  </li>
                </ul>
              </div>
            </li>-->
            <li>
                <router-link to="/article-list" class="action">
                    <i class="fas fa-text"></i>
                    <span>Статьи</span>
                </router-link>
            </li>
            <li>
                <router-link to="/media-list" class="action">
                    <i class="far fa-image"></i>
                    <span>Медиа-ресурсы</span>
                </router-link>
                <!--        <div class="sub">-->
                <!--          <ul>-->
                <!--            <li>-->
                <!--              <a href="index.php?action=image.list">Картинки</a>-->
                <!--            </li>-->
                <!--            <li>-->
                <!--              <a href="index.php?module=main">Видео</a>-->
                <!--            </li>-->
                <!--            <li>-->
                <!--              <a href="index.php?module=main">Аудио</a>-->
                <!--            </li>-->
                <!--          </ul>-->
                <!--        </div>-->
            </li>
            <li>
                <router-link to="/tag-list" class="action">
                    <i class="fas fa-tags"></i>
                    <span>Теги</span>
                </router-link>
            </li>
            <li>
                <router-link to="/advert-list" class="action">
                    <i class="fas fa-ad"></i>
                    <span>Реклама</span>
                </router-link>
            </li>
            <li>
                <router-link to="/analytics-list" class="action">
                    <i class="fas fa-analytics"></i>
                    <span>Аналитика</span>
                </router-link>
            </li>
            <!-- <li>
              <a href="index.php?action=analytics.list">
                <i class="fa fa-line-chart"></i>
                <span>Аналитика</span>
              </a>
              <div class="sub">
                <ul>
                  <li>
                    <a href="index.php?action=analytics.create">Создать</a>
                  </li>
                  <li>
                    <a href="index.php?action=analytics.list">Список</a>
                  </li>
                </ul>
              </div>
            </li>
            <li>
              <a href="index.php?action=advert.list">
                <i class="fa fa-usd"></i>
                <span>Реклама</span>
              </a>
              <div class="sub">
                <ul>
                  <li>
                    <a href="index.php?action=advert.create">Создать</a>
                  </li>
                  <li>
                    <a href="index.php?action=advert.list">Список</a>
                  </li>
                </ul>
              </div>
            </li>
            <li>
              <a href="index.php?module=help">
                <i class="fa fa-info-circle"></i>
                <span>Помощь</span>
              </a>
            </li>
            <li>
              <a href="index.php?module=sitemap">
                <i class="fa fa-sitemap"></i>
                <span>Карта сайта</span>
              </a>
            </li>
            <li>
              <a href="index.php?module=update">
                <i class="fa fa-cloud-upload"></i>
                <span>Обновления</span>
              </a>
            </li>
            <li>
              <a href="index.php?module=config">
                <i class="fa fa-cog"></i>
                <span>Настройки</span>
              </a>
            </li>-->
            <span
                style="color: #919191;font-size: 10px;text-align: center;width: 100%;display: block;"
            >v0.1.0</span>
        </ul>
    </div>
</template>

<script>
export default {
    name: "Menu-Left",
    props: {},
};
</script>

<style scoped lang="scss">
.menu {
    background-color: #23282d;

    ul {
        margin: 20px 0px;
        list-style: none;
        padding: 0;

        > li {
            margin-bottom: 5px;

            a {
                display: block;

                line-height: 34px;
                font-size: 14px;
                font-weight: 400;
                height: 34px;
                color: #fff;
                text-decoration: none;

                i {
                    color: #999;
                    margin: 0px 6px;
                    font-size: 1.5em;
                    height: 100%;
                }

                &:hover {
                    background-color: #191e23;
                    color: #00b9eb;
                }
            }

            :hover {
                background-color: #191e23;
                color: #00b9eb;

                .sub {
                    display: block;
                }

                .fa {
                    color: #00b9eb;
                }

                a {
                    color: #00b9eb;
                }
            }
        }
    }

    .sub {
        display: none;
        position: relative;
        top: -46px;

        li {
            position: absolute;
            background-color: #32373c;
            left: 160px;
            padding: 7px 0 4px;
        }

        a {
            font-size: 12px;
            color: #b4b9be;
            padding: 1px 2px 0px 15px;

            :hover {
                color: #00b9eb;
                background-color: #32373c;
            }
        }
    }
}
</style>
