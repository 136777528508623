<template>
  <div>
    <div class="container">
      <div class="row">
        <h2 class="col-12 my-3">Блоки аналитики</h2>
      </div>

      <br/>

      <div class="btn-toolbar" role="toolbar">
        <router-link to="/analytics-create" class="action">
          <button type="button" class="btn btn-primary mr-3">
            <i class="fas fa-plus-square"></i> Создать
          </button>
        </router-link>
      </div>

      <div class="my-4">
        <span>Найдено {{ analytics.length }} блоков аналитики</span>
      </div>

      <br/>

      <table class="table table-hover">
        <thead>
        <tr>
          <th>ID</th>
          <th>Название</th>
          <th>Действия</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="analytic in analytics" :key="analytic.Id">
          <td>{{ analytic.Id }}</td>
          <td>{{ analytic.Name }}</td>
          <td>
            <router-link :to="{ name: 'analytics-edit', params: { id: analytic.Id }}" class="action">
              <i class="fa fa-pencil"></i>
            </router-link>

            <a to="#" class="action" @click="enable(analytic.Id)" v-show="!analytic.IsEnabled">
              <i class="fad fa-toggle-off" style="color: red"></i>
            </a>
            <a to="#" class="action" @click="disable(analytic.Id)" v-show="analytic.IsEnabled">
              <i class="fad fa-toggle-on" style="color: green"></i>
            </a>
            <a to="#" class="action" @click="remove(analytic.Id)">
              <i class="fas fa-trash"></i>
            </a>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {AnalyticsGetAll, AnalyticsEnable, AnalyticsDisable, AnalyticsRemove} from "@/api";

export default {
  name: "Analytics-List",
  components: {},
  data: function () {
    return {
      analytics: [],
    };
  },
  watch: {},
  created() {
    this.getAll();
  },
  computed: {
  },
  methods: {
    getAll: function () {
      AnalyticsGetAll("Id,Name,IsEnabled")
      .then((data) => {
        this.analytics = data.Document;
      })
      .catch((error) => console.error(error));
    },
    enable: function (id) {
      AnalyticsEnable(id)
      .then(() => {
        // let advert = data.Document;
        //
        // let foundIndex = this.analytics.findIndex(x => x.Id === advert.Id);
        //
        // this.$set(this.analytics, foundIndex, advert);

        this.analytics.find(x => x.Id === id).IsEnabled = true;
      })
      .catch((error) => console.error(error));
    },
    disable: function (id) {
      AnalyticsDisable(id)
      .then(() => {
        // let advert = data.Document;
        //
        // let foundIndex = this.analytics.findIndex(x => x.Id === advert.Id);
        //
        // this.$set(this.analytics, foundIndex, advert);

        this.analytics.find(x => x.Id === id).IsEnabled = false;
      })
      .catch((error) => console.error(error));
    },
    remove: function (id) {
      AnalyticsRemove(id)
      .then(() => {
        // let advert = data.Document;
        //
        // let foundIndex = this.analytics.findIndex(x => x.Id === advert.Id);
        //
        // this.$set(this.analytics, foundIndex, advert);

        // this.analytics.find(x => x.Id === id).IsEnabled = false;
        this.$delete(this.analytics, this.analytics.findIndex(x => x.Id === id))
      })
      .catch((error) => console.error(error));
    }
  },
};
</script>

<style scoped lang="scss">
table {
  a {
    text-decoration: none;
    color: #000000;
  }

  .action {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    padding: 5px;
    margin: 0 2px;
    height: 36px;
    width: 36px;

    border: 1px solid transparent;
    border-radius: 5px;

    color: #23282d;

    &:hover {
      color: #007bff;

      border: 1px solid #007bff;
    }
  }
}
</style>