<template>
    <div>
        <div class="container">
            <div class="row">
                <h2 class="col-12 my-3">Главная</h2>

                <div class="col-lg-6 mt-3">
                    <div class="card">
                        <div class="card-body">
                            <h5 class="card-title">Страницы</h5>
                            <p class="card-text">Всего на сайте страниц: {{ stats.Page.Count }}</p>
                            <p class="card-text">В среднем заголовок страницы состоит из {{
                                    stats.Page.AverageTitleCount
                                }} символов.</p>
                            <p class="card-text">В среднем содержание страницы состоит из {{
                                    stats.Page.AverageBodyCount
                                }} символов.</p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 mt-3">
                    <div class="card">
                        <div class="card-body">
                            <h5 class="card-title">Статистика создания страниц</h5>
                            <Bar
                                :data="chartData"
                                :options="chartOptions"
                                :height="200"
                                :width="640"
                            />
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 mt-3">
                    <div class="card">
                        <div class="card-body">
                            <h5 class="card-title">Медиа</h5>
                            <p class="card-text">Всего на сайт загружено медиа: {{ stats.Media.Count }}</p>
                            <p class="card-text">Всего загруженные медиа весят:
                                {{ MegabytesByBytes(stats.Media.TotalBytes) }} мегабайт.</p>
                            <p class="card-text">В среднем медиа весят: {{ KilobytesByBytes(stats.Media.AverageBytes) }}
                                килобайт.</p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 mt-3">
                    <div class="card">
                        <div class="card-body">
                            <h5 class="card-title">Реклама</h5>
                            <p class="card-text">На сайте включено блоков рекламы: {{ stats.EnabledAdverts }}</p>
                        </div>
                    </div>

                    <div class="card mt-3">
                        <div class="card-body">
                            <h5 class="card-title">Аналитика</h5>
                            <p class="card-text">На сайте включено блоков аналитики: {{ stats.EnabledAnalytics }}</p>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import {Home} from "@/api";

import {Bar} from 'vue-chartjs'
import {Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)


// import Chart from "@/components/Chart/Bar.vue";

export default {
    name: "App-Home",
    components: {
        Bar
    },
    data: function () {
        return {
            stats: {
                EnabledAdverts: 0,
                EnabledAnalytics: 0,
                Media: {
                    TotalBytes: 0,
                    AverageBytes: 0,
                    Count: 0
                },
                Page: {
                    Count: 0,
                    AverageTitleCount: 0,
                    AverageBodyCount: 0,
                    StatisticCreate: {}
                }
            },

            chartSettings: {
                options: {
                    responsive: true
                },
                chartdata: {
                    labels: [],
                    datasets: []
                }
            }
        };
    },
    created() {
        this.get();
    },
    computed: {
        chartData() {
            return this.chartSettings.chartdata
        },
        chartOptions() {
            return this.chartSettings.options
        }
    },
    methods: {
        MegabytesByBytes: function (bytes) {
            return (bytes / 1024 / 1024) | 0;
        },
        KilobytesByBytes: function (bytes) {
            return (bytes / 1024) | 0;
        },
        generateChart: function (obj) {
            // debugger;
            let arr = Object.entries(obj).sort((a, b) => {
                return a[0].localeCompare(b[0]);
            });
            // console.log(arr);

            let labels = arr.map(a => a[0]);
            let datasets = [
                {
                    label: "Количество страниц",
                    backgroundColor: "rgb(54, 162, 235)",
                    data: arr.map(a => a[1])
                    // lineTension: 0.0001
                }
            ];

            // console.log(labels);
            // console.log(datasets);

            // this.chartSettings.labels = arr.map(a => a[0])
            // this.$set(this.chartSettings, "labels", labels);
            this.chartSettings.chartdata = {
                labels: labels,
                datasets: datasets
            }

            // this.chartSettings.datasets = arr.map(a => a[1])
            // this.$set(this.chartSettings, "datasets", datasets);
        },
        get: function () {
            Home()
                .then((data) => {
                    // this.stats = data.Document;

                    this.stats = data.Document;
                    // this.$set(this, "stats", data.Document);

                    this.generateChart(this.stats.Page.StatisticCreate);

                    // this.$set(this, "lossData", newData.data);
                })
                .catch((error) => console.error(error));
        },
    },
};
</script>

<style lang="scss" scoped>
.wrapper {
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.header {
    font-size: 30px;

    /*margin: 50px 0;*/
    text-align: center;
}

.BerkshireSwash {
    font-family: "Berkshire Swash";
    font-size: 30px;

    /*margin: 50px 0;*/
    text-align: center;
}

.profile {
    background-color: white;

    margin-top: 10vh;

    width: 100%;
    max-width: 300px;

    border-radius: 20px;

    & img.logo {
        display: flex;
        width: 100%;
        margin: 20px 0;
    }
}

.home {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto;
}

.photo {
    display: grid;
    justify-content: center;

    img {
        border-radius: 50%;
        width: 128px;
        height: 128px;
        border: 1px black solid;
    }
}

.nickname {
    font-size: 25px;
    font-weight: 700;
    margin: 10px 10px 10px 0;
    text-align: center;

    padding: 10px;
}

.title {
    font-size: 20px;
    font-weight: 700;
    margin: 10px 10px 10px 0;
}

.actions {
    a.action {
        text-decoration: none;
        display: block;
        line-height: 1.25em;
        margin: 5px 0;
    }

    a.action:visited {
        color: #4252ff;
    }
}

.provider {
    .name {
        display: inline;
        background-color: #5181b8;
        color: #fff;
        font-size: 12.5px;
        padding: 7px 16px 8px;
        line-height: 15px;
        text-align: center;
        text-decoration: none;
        border-radius: 4px 0 0 4px;
    }

    .action {
        grid-area: action;
        border-radius: 0 4px 4px 0;
        grid-area: name;
        display: inline;
        background-color: #e6e6e6;
        color: #000;
        font-size: 12.5px;
        padding: 7px 16px 8px;
        line-height: 15px;
        text-align: center;
        text-decoration: none;

        &.add {
            &:hover {
                background-color: #21bd1c;
                color: #e6e6e6;
            }
        }

        &.remove {
            &:hover {
                background-color: #d54747;
                color: #e6e6e6;
            }
        }
    }
}
</style>