<template>
    <div>
        <div class="container">
            <div class="row">
                <h2 class="col-12 my-3">Создание статьи</h2>
            </div>
            <div class="row">
                <div class="col-8">
                    <div class="form-group">
                        <label for="article-title">Заголовок</label>
                        <textarea
                            class="form-control form-control-lg"
                            id="article-title"
                            rows="3"
                            v-model="title"
                        ></textarea>
                    </div>

                    <div class="form-group">
                        <label for="article-description">Описание</label>
                        <textarea
                            class="form-control form-control-lg"
                            id="article-description"
                            rows="3"
                            v-model="description"
                        ></textarea>
                    </div>

                    <div class="form-group">
                        <label for="article-keywords">Ключевые слова</label>
                        <textarea
                            class="form-control form-control-lg"
                            id="article-keywords"
                            rows="3"
                            v-model="keywords"
                        ></textarea>
                    </div>
                </div>

                <div class="col-4">
                    <div class="form-group">
                        <label for="article-image">Главное изображение</label>
                        <input type="text" class="form-control" id="article-image" v-model="mainImageShortId"/>
                    </div>
                    <div>
                        <img :src="mainImage.Link" alt class="image-preview"/>
                    </div>
                </div>

                <div class="col-12 my-2">
                    <div class="card card-body">
                        <span class="mb-3">Поиск</span>

                        <div class="input-group mb-3">
                            <input type="text" class="form-control" placeholder="Ключевое слово"
                                   v-model="tagSearchQuery"/>
                        </div>

                        <div>
                            <span class="tag" v-for="(el, i) in tagSearchResult" :key="i" @click="addTag(el)">{{
                                    el.Name
                                }}<span><i class="fal fa-plus"></i></span></span>
                        </div>

                        <span class="my-3">Ключевые слова</span>

                        <div>
                            <span class="tag" v-for="(el, i) in tags" :key="i" @click="removeTag(el.Id)">{{
                                    el.Name
                                }}<span><i class="fal fa-times"></i></span></span>
                        </div>
                    </div>
                </div>

                <div class="col-12 mt-5">
                    <div class="form-group">
<!--                        <ckeditor :editor="editor" v-model="editorData" :config="editorConfig"></ckeditor>-->
                        <SmartEditor v-model="editorData"></SmartEditor>
                    </div>
                </div>

                <div class="col-12 mt-3">
                    <button type="button" class="btn btn-primary" @click="create">Создать</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {MediaGetShort, PageCreate, TagSearch} from "@/api";

// import Editor from 'ckeditor5-custom-build/build/ckeditor';
import SmartEditor from "@/components/SmartEditor.vue";

export default {
    name: "Article-Create",
    components: {SmartEditor},
    data: function () {
        return {
            page: {},

            title: "",
            description: "",
            keywords: "",

            tags: [],

            tagSearchQuery: "",
            tagSearchResult: [],

            mainImageShortId: "",
            mainImage: {
                Link: "#"
            },

            // editor: Editor,
            editorData: "<p>Текст статьи</p>",
            editorConfig: {
                toolbar: {
                    items: [
                        'undo',
                        'redo',
                        '|',
                        'fontSize',
                        'heading',
                        '|',
                        'fontColor',
                        'highlight',
                        '|',
                        'bold',
                        'italic',
                        'underline',
                        'bulletedList',
                        'numberedList',
                        '|',
                        'removeFormat',
                        '|',
                        'indent',
                        'outdent',
                        'alignment',
                        '|',
                        'insertTable',
                        '|',
                        'link',
                        '|',
                        'fontFamily',
                        'fontBackgroundColor',
                        'strikethrough',
                        'sourceEditing',
                    ]
                },
                language: "ru",
                table: {
                    contentToolbar: ["tableColumn", "tableRow", "mergeTableCells", 'tableCellProperties', 'tableProperties'],
                },
                licenseKey: "",
            },
        };
    },
    watch: {
        mainImageShortId: function () {
            this.debounceimageGet();
        },
        tagSearchQuery: function () {
            this.debounceTagSearch();
        }
    },
    created() {
        // this.get();
    },
    computed: {
        // getImgUrl() {
        //   console.log(this.mainImageId);
        //   if (this.mainImageId) {
        //     return `${process.env.VUE_APP_API_BASE}/api/media/file/${this.mainImageId}`;
        //   }
        //
        //   return "#";
        // },
    },
    methods: {
        addTag: function (tag) {
            let foundIndex = this.tags.findIndex(t => t.Id === tag.Id);
            if (foundIndex === -1) {
                this.tags.push(tag);
            }
        },
        removeTag: function (tagId) {
            let foundIndex = this.tags.findIndex(t => t.Id === tagId);
            if (foundIndex !== -1) {
                this.tags.splice(foundIndex, 1);
            }
        },
        debounceTagSearch: function () {
            clearTimeout(this.debounce);
            this.debounce = setTimeout(() => {
                this.tagSearch();
            }, 600);
        },
        debounceimageGet: function () {
            clearTimeout(this.debounce);
            this.debounce = setTimeout(() => {
                this.imageGetShort();
            }, 600);
        },
        imageGetShort: function () {
            MediaGetShort(this.mainImageShortId)
                .then((data) => {
                    this.mainImage = data.Document;
                })
                .catch((error) => console.error(error));
        },
        tagSearch: function () {
            TagSearch(this.tagSearchQuery)
                .then((data) => {
                    this.tagSearchResult = data.Document;
                })
                .catch((error) => console.error(error));
        },
        create: function () {
            PageCreate(this.title, this.description, this.keywords, this.editorData, this.mainImage.Id, this.tags)
                .then((data) => {
                    if (data.IsSuccess) {
                        this.$router.push({name: 'article-list'});
                    }
                    // console.log(data);
                })
                .catch((error) => console.error(error));
        },
    },
};
</script>

<style scoped lang="scss">
.image-preview {
    max-width: 300px;
    max-height: 300px;
}

.tag {
    border: 1px solid var(--gray);
    border-radius: 5px;
    padding: 5px 10px;
    margin: 5px 10px 0 0;

    display: inline-flex;

    & > span {
        margin: 0 5px;

        &:hover {
            color: red;
        }
    }
}
</style>