<template>
  <div>
    <div class="container">
      <div class="row">
        <h2 class="col-12 my-3">Блоки рекламы</h2>
      </div>

      <br/>

      <div class="btn-toolbar" role="toolbar">
        <router-link to="/advert-create" class="action">
          <button type="button" class="btn btn-primary mr-3">
            <i class="fas fa-plus-square"></i> Создать
          </button>
        </router-link>
      </div>

      <div class="my-4">
        <span>Найдено {{ adverts.length }} блоков рекламы</span>
      </div>

      <br/>

      <table class="table table-hover">
        <thead>
        <tr>
          <th>ID</th>
          <th>Название</th>
          <th>Действия</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="advert in adverts" :key="advert.Id">
          <td>{{ advert.Id }}</td>
          <td>{{ advert.Name }}</td>
          <td>
            <router-link :to="{ name: 'advert-edit', params: { id: advert.Id }}" class="action">
              <i class="fa fa-pencil"></i>
            </router-link>

            <a to="#" class="action" @click="enable(advert.Id)" v-show="!advert.IsEnabled">
              <i class="fad fa-toggle-off" style="color: red"></i>
            </a>
            <a to="#" class="action" @click="disable(advert.Id)" v-show="advert.IsEnabled">
              <i class="fad fa-toggle-on" style="color: green"></i>
            </a>
            <a to="#" class="action" @click="remove(advert.Id)">
              <i class="fas fa-trash"></i>
            </a>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {AdvertGetAll, AdvertEnable, AdvertDisable, AdvertRemove} from "@/api";

export default {
  name: "Advert-List",
  components: {},
  data: function () {
    return {
      adverts: [],
    };
  },
  watch: {},
  created() {
    this.getAll();
  },
  computed: {
  },
  methods: {
    getAll: function () {
      AdvertGetAll("Id,Name,IsEnabled")
      .then((data) => {
        this.adverts = data.Document;
      })
      .catch((error) => console.error(error));
    },
    enable: function (id) {
      AdvertEnable(id)
      .then(() => {
        // let advert = data.Document;
        // console.log(data);

        // let foundIndex = this.adverts.findIndex();

        this.adverts.find(x => x.Id === id).IsEnabled = true;

        // this.$set(, , advert);
      })
      .catch((error) => console.error(error));
    },
    disable: function (id) {
      AdvertDisable(id)
      .then(() => {
        // let advert = data.Document;
        // console.log(data);

        // let foundIndex = this.adverts.findIndex(x => x.Id === advert.Id);
        //
        // this.$set(this.adverts, foundIndex, advert);

        this.adverts.find(x => x.Id === id).IsEnabled = false;
      })
      .catch((error) => console.error(error));
    },
    remove: function (id) {
      AdvertRemove(id)
      .then(() => {
        // let advert = data.Document;
        // console.log(data);

        // let foundIndex = this.adverts.findIndex(x => x.Id === advert.Id);
        //
        // this.$set(this.adverts, foundIndex, advert);
        this.$delete(this.adverts, this.adverts.findIndex(x => x.Id === id))
      })
      .catch((error) => console.error(error));
    }
  },
};
</script>

<style scoped lang="scss">
table {
  a {
    text-decoration: none;
    color: #000000;
  }

  .action {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    padding: 5px;
    margin: 0 2px;
    height: 36px;
    width: 36px;

    border: 1px solid transparent;
    border-radius: 5px;

    color: #23282d;

    &:hover {
      color: #007bff;

      border: 1px solid #007bff;
    }
  }
}
</style>