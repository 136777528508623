const base = process.env.VUE_APP_API_BASE;

import Store from "@/store";

function serialize(object) {
  let query = [];
  for (let property in object) {
    if (Object.prototype.hasOwnProperty.call(object, property)) {
      query.push(encodeURIComponent(property) + "=" + encodeURIComponent(object[property]));
    }
  }
  return query.join("&");
}

export async function Profile() {
  try {
    const response = await fetch(`${base}/api/user/profile`, {
      method: "GET",
      mode: "cors", // no-cors, cors, *same-origin
      cache: "no-cache",
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        // "Content-Type": "application/json",
        "Accept": "application/json",
      },
      // redirect: "follow", // manual, *follow, error
      // referrer: "no-referrer", // no-referrer, *client
      // body: JSON.stringify(req),
    });
    let json = await response.json();

    if (!json.IsSuccess)
    {
      console.error(json);
      Store.commit("NotificationAdd", {
        type: "error",
        text: json.ErrorMessage
      });

      return {
        IsSuccess: false
      }
    }

    return json;

  } catch (error) {
    console.error(error);
    Store.commit("NotificationAdd", {
      type: "error",
      text: error
    });

    return {
      IsSuccess: false
    }
  }
}

export async function Home() {
  try {
    const response = await fetch(`${base}/api/home/get`, {
      method: "GET",
      mode: "cors", // no-cors, cors, *same-origin
      cache: "no-cache",
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        // "Content-Type": "application/json",
        "Accept": "application/json",
      },
      // redirect: "follow", // manual, *follow, error
      // referrer: "no-referrer", // no-referrer, *client
      // body: JSON.stringify(req),
    });
    let json = await response.json();

    if (!json.IsSuccess)
    {
      console.error(json);
      Store.commit("NotificationAdd", {
        type: "error",
        text: json.ErrorMessage
      });

      return {
        IsSuccess: false
      }
    }

    return json;

  } catch (error) {
    console.error(error);
    Store.commit("NotificationAdd", {
      type: "error",
      text: error
    });

    return {
      IsSuccess: false
    }
  }
}

export async function PageGet(id) {
  try {
    const request = {
      Id: id,
    }
    let queryString = `?${serialize(request)}`;
    const response = await fetch(`${base}/api/page/get${queryString}`, {
      method: "GET",
      mode: "cors", // no-cors, cors, *same-origin
      cache: "no-cache",
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        // "Content-Type": "application/json",
        "Accept": "application/json",
      },
      // redirect: "follow", // manual, *follow, error
      // referrer: "no-referrer", // no-referrer, *client
      // body: JSON.stringify(request),
    });
    let json = await response.json();

    if (!json.IsSuccess)
    {
      console.error(json);
      Store.commit("NotificationAdd", {
        type: "error",
        text: json.ErrorMessage
      });

      return {
        IsSuccess: false
      }
    }

    return json;

  } catch (error) {
    console.error(error);
    Store.commit("NotificationAdd", {
      type: "error",
      text: error
    });

    return {
      IsSuccess: false
    }
  }
}
export async function PageUpdate(id, title, description, keywords, body, image, tags) {
  try {
    const request = {
      Title: title,
      Description: description,
      Keywords: keywords,
      Body: body,
      MainImage: image,
      Tags: tags,
    }
    // let queryString = `?${serialize(request)}`;
    const response = await fetch(`${base}/api/page/update/${id}`, {
      method: "POST",
      mode: "cors", // no-cors, cors, *same-origin
      cache: "no-cache",
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
      // redirect: "follow", // manual, *follow, error
      // referrer: "no-referrer", // no-referrer, *client
      body: JSON.stringify(request),
    });
    let json = await response.json();

    if (!json.IsSuccess)
    {
      console.error(json);
      Store.commit("NotificationAdd", {
        type: "error",
        text: json.ErrorMessage
      });

      return {
        IsSuccess: false
      }
    }

    return json;

  } catch (error) {
    console.error(error);
    Store.commit("NotificationAdd", {
      type: "error",
      text: error
    });

    return {
      IsSuccess: false
    }
  }
}
export async function PageSearch(query, page, itemsOnPage, sortField, sortDirection, includeBody, fields) {
  try {
    const request = {
      Query: query,
      SortField: sortField,
      SortDirection: sortDirection,
      SearchIncludeBody: includeBody,
      Page: page,
      ItemsOnPage: itemsOnPage,
      Fields: fields
    }
    let queryString = `?${serialize(request)}`;
    const response = await fetch(`${base}/api/page/search${queryString}`, {
      method: "GET",
      mode: "cors", // no-cors, cors, *same-origin
      cache: "no-cache",
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        // "Content-Type": "application/json",
        "Accept": "application/json",
      },
      // redirect: "follow", // manual, *follow, error
      // referrer: "no-referrer", // no-referrer, *client
      // body: JSON.stringify(request),
    });
    let json = await response.json();

    if (!json.IsSuccess)
    {
      console.error(json);
      Store.commit("NotificationAdd", {
        type: "error",
        text: json.ErrorMessage
      });

      return {
        IsSuccess: false
      }
    }

    return json;

  } catch (error) {
    console.error(error);
    Store.commit("NotificationAdd", {
      type: "error",
      text: error
    });

    return {
      IsSuccess: false
    }
  }
}
export async function PageCreate(title, description, keywords, body, mainImage, tags) {
  try {
    const request = {
      Title: title,
      Description: description,
      Keywords: keywords,
      Body: body,
      MainImage: mainImage,
      Tags: tags
    }
    // let queryString = `?${serialize(request)}`;
    const response = await fetch(`${base}/api/page/create`, {
      method: "POST",
      mode: "cors", // no-cors, cors, *same-origin
      cache: "no-cache",
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
      // redirect: "follow", // manual, *follow, error
      // referrer: "no-referrer", // no-referrer, *client
      body: JSON.stringify(request),
    });
    let json = await response.json();

    if (!json.IsSuccess)
    {
      console.error(json);
      Store.commit("NotificationAdd", {
        type: "error",
        text: json.ErrorMessage
      });

      return {
        IsSuccess: false
      }
    }

    return json;

  } catch (error) {
    console.error(error);
    Store.commit("NotificationAdd", {
      type: "error",
      text: error
    });

    return {
      IsSuccess: false
    }
  }
}
export async function PageGetAllByTagId(id, page, itemsOnPage) {
  try {
    // const request = {
    //   Id: id,
    // }
    // let queryString = `?${serialize(request)}`;
    const request = {
      Page: page,
      ItemsOnPage: itemsOnPage
    }
    let queryString = `?${serialize(request)}`;
    const response = await fetch(`${base}/api/page/getAllByTagId/${id}${queryString}`, {
      method: "GET",
      mode: "cors", // no-cors, cors, *same-origin
      cache: "no-cache",
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        // "Content-Type": "application/json",
        "Accept": "application/json",
      },
      // redirect: "follow", // manual, *follow, error
      // referrer: "no-referrer", // no-referrer, *client
      // body: JSON.stringify(request),
    });
    let json = await response.json();

    if (!json.IsSuccess)
    {
      console.error(json);
      Store.commit("NotificationAdd", {
        type: "error",
        text: json.ErrorMessage
      });

      return {
        IsSuccess: false
      }
    }

    return json;

  } catch (error) {
    console.error(error);
    Store.commit("NotificationAdd", {
      type: "error",
      text: error
    });

    return {
      IsSuccess: false
    }
  }
}
export async function PageGetAllByMainImageId(id, page, itemsOnPage) {
  try {
    // const request = {
    //   Id: id,
    // }
    // let queryString = `?${serialize(request)}`;
    const request = {
      Page: page,
      ItemsOnPage: itemsOnPage
    }
    let queryString = `?${serialize(request)}`;
    const response = await fetch(`${base}/api/page/getAllByMainImageId/${id}${queryString}`, {
      method: "GET",
      mode: "cors", // no-cors, cors, *same-origin
      cache: "no-cache",
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        // "Content-Type": "application/json",
        "Accept": "application/json",
      },
      // redirect: "follow", // manual, *follow, error
      // referrer: "no-referrer", // no-referrer, *client
      // body: JSON.stringify(request),
    });
    let json = await response.json();

    if (!json.IsSuccess)
    {
      console.error(json);
      Store.commit("NotificationAdd", {
        type: "error",
        text: json.ErrorMessage
      });

      return {
        IsSuccess: false
      }
    }

    return json;

  } catch (error) {
    console.error(error);
    Store.commit("NotificationAdd", {
      type: "error",
      text: error
    });

    return {
      IsSuccess: false
    }
  }
}
export async function PageGetAllWithImage(id, page, itemsOnPage) {
  try {
    // const request = {
    //   Id: id,
    // }
    // let queryString = `?${serialize(request)}`;
    const request = {
      Page: page,
      ItemsOnPage: itemsOnPage
    }
    let queryString = `?${serialize(request)}`;
    const response = await fetch(`${base}/api/page/getAllWithImage/${id}${queryString}`, {
      method: "GET",
      mode: "cors", // no-cors, cors, *same-origin
      cache: "no-cache",
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        // "Content-Type": "application/json",
        "Accept": "application/json",
      },
      // redirect: "follow", // manual, *follow, error
      // referrer: "no-referrer", // no-referrer, *client
      // body: JSON.stringify(request),
    });
    let json = await response.json();

    if (!json.IsSuccess)
    {
      console.error(json);
      Store.commit("NotificationAdd", {
        type: "error",
        text: json.ErrorMessage
      });

      return {
        IsSuccess: false
      }
    }

    return json;

  } catch (error) {
    console.error(error);
    Store.commit("NotificationAdd", {
      type: "error",
      text: error
    });

    return {
      IsSuccess: false
    }
  }
}

export async function MediaUpload(description, type, file) {
  try {
    var request = new FormData();
    request.append('Description', description);
    request.append('Type', type);
    request.append('File', file);

    const response = await fetch(`${base}/api/media/upload`, {
      method: "POST",
      mode: "cors", // no-cors, cors, *same-origin
      cache: "no-cache",
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        // "Content-Type": "multipart/form-data",
        // "Accept": "application/json",
      },
      // redirect: "follow", // manual, *follow, error
      // referrer: "no-referrer", // no-referrer, *client
      body: request
    });
    let json = await response.json();

    if (!json.IsSuccess)
    {
      console.error(json);
      Store.commit("NotificationAdd", {
        type: "error",
        text: json.ErrorMessage
      });

      return {
        IsSuccess: false
      }
    }

    return json;

  } catch (error) {
    console.error(error);
    Store.commit("NotificationAdd", {
      type: "error",
      text: error
    });

    return {
      IsSuccess: false
    }
  }
}
export async function MediaSearch(query, page, itemsOnPage, sortField, sortDirection) {
  try {
    const request = {
      Query: query,
      Page: page,
      ItemsOnPage: itemsOnPage,
      SortField: sortField,
      SortDirection: sortDirection
    }
    let queryString = `?${serialize(request)}`;
    const response = await fetch(`${base}/api/media/search${queryString}`, {
      method: "GET",
      mode: "cors", // no-cors, cors, *same-origin
      cache: "no-cache",
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        // "Content-Type": "application/json",
        "Accept": "application/json",
      },
      // redirect: "follow", // manual, *follow, error
      // referrer: "no-referrer", // no-referrer, *client
      // body: JSON.stringify(request),
    });
    let json = await response.json();

    if (!json.IsSuccess)
    {
      console.error(json);
      Store.commit("NotificationAdd", {
        type: "error",
        text: json.ErrorMessage
      });

      return {
        IsSuccess: false
      }
    }

    return json;

  } catch (error) {
    console.error(error);
    Store.commit("NotificationAdd", {
      type: "error",
      text: error
    });

    return {
      IsSuccess: false
    }
  }
}
export async function MediaUpdate(id, description) {
  try {
    const request = {
      Description: description,
    }
    const response = await fetch(`${base}/api/media/update/${id}`, {
      method: "POST",
      mode: "cors", // no-cors, cors, *same-origin
      cache: "no-cache",
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
      // redirect: "follow", // manual, *follow, error
      // referrer: "no-referrer", // no-referrer, *client
      body: JSON.stringify(request)
      // body: `"${description}"`
    });
    let json = await response.json();

    if (!json.IsSuccess)
    {
      console.error(json);
      Store.commit("NotificationAdd", {
        type: "error",
        text: json.ErrorMessage
      });

      return {
        IsSuccess: false
      }
    }

    return json;

  } catch (error) {
    console.error(error);
    Store.commit("NotificationAdd", {
      type: "error",
      text: error
    });

    return {
      IsSuccess: false
    }
  }
}
export async function MediaGet(id) {
  try {
    const response = await fetch(`${base}/api/media/get/${id}`, {
      method: "GET",
      mode: "cors", // no-cors, cors, *same-origin
      cache: "no-cache",
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        // "Content-Type": "multipart/form-data",
        // "Accept": "application/json",
      },
      // redirect: "follow", // manual, *follow, error
      // referrer: "no-referrer", // no-referrer, *client
      // body: JSON.stringify(request)
    });
    let json = await response.json();

    if (!json.IsSuccess)
    {
      console.error(json);
      Store.commit("NotificationAdd", {
        type: "error",
        text: json.ErrorMessage
      });

      return {
        IsSuccess: false
      }
    }

    return json;

  } catch (error) {
    console.error(error);
    Store.commit("NotificationAdd", {
      type: "error",
      text: error
    });

    return {
      IsSuccess: false
    }
  }
}
export async function MediaGetShort(id) {
  try {
    const response = await fetch(`${base}/api/media/get/shortid/${id}`, {
      method: "GET",
      mode: "cors", // no-cors, cors, *same-origin
      cache: "no-cache",
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        // "Content-Type": "multipart/form-data",
        // "Accept": "application/json",
      },
      // redirect: "follow", // manual, *follow, error
      // referrer: "no-referrer", // no-referrer, *client
      // body: JSON.stringify(request)
    });
    let json = await response.json();

    if (!json.IsSuccess)
    {
      console.error(json);
      Store.commit("NotificationAdd", {
        type: "error",
        text: json.ErrorMessage
      });

      return {
        IsSuccess: false
      }
    }

    return json;

  } catch (error) {
    console.error(error);
    Store.commit("NotificationAdd", {
      type: "error",
      text: error
    });

    return {
      IsSuccess: false
    }
  }
}
export async function MediaRemove(id) {
  try {
    const response = await fetch(`${base}/api/media/remove/${id}`, {
      method: "POST",
      mode: "cors", // no-cors, cors, *same-origin
      cache: "no-cache",
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        // "Content-Type": "multipart/form-data",
        // "Accept": "application/json",
      },
      // redirect: "follow", // manual, *follow, error
      // referrer: "no-referrer", // no-referrer, *client
      // body: JSON.stringify(request)
    });
    let json = await response.json();

    if (!json.IsSuccess)
    {
      console.error(json);
      Store.commit("NotificationAdd", {
        type: "error",
        text: json.ErrorMessage
      });

      return {
        IsSuccess: false
      }
    }

    return json;

  } catch (error) {
    console.error(error);
    Store.commit("NotificationAdd", {
      type: "error",
      text: error
    });

    return {
      IsSuccess: false
    }
  }
}

export async function TagSearch(query) {
  try {
    const request = {
      Query: query
    }
    let queryString = `?${serialize(request)}`;
    const response = await fetch(`${base}/api/tag/search${queryString}`, {
      method: "GET",
      mode: "cors", // no-cors, cors, *same-origin
      cache: "no-cache",
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        // "Content-Type": "application/json",
        "Accept": "application/json",
      },
      // redirect: "follow", // manual, *follow, error
      // referrer: "no-referrer", // no-referrer, *client
      // body: JSON.stringify(request),
    });
    let json = await response.json();

    if (!json.IsSuccess)
    {
      console.error(json);
      Store.commit("NotificationAdd", {
        type: "error",
        text: json.ErrorMessage
      });

      return {
        IsSuccess: false
      }
    }

    return json;

  } catch (error) {
    console.error(error);
    Store.commit("NotificationAdd", {
      type: "error",
      text: error
    });

    return {
      IsSuccess: false
    }
  }
}
export async function TagGet(id) {
  try {
    const response = await fetch(`${base}/api/tag/get/${id}`, {
      method: "GET",
      mode: "cors", // no-cors, cors, *same-origin
      cache: "no-cache",
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        // "Content-Type": "application/json",
        "Accept": "application/json",
      },
      // redirect: "follow", // manual, *follow, error
      // referrer: "no-referrer", // no-referrer, *client
      // body: JSON.stringify(request),
    });
    let json = await response.json();

    if (!json.IsSuccess)
    {
      console.error(json);
      Store.commit("NotificationAdd", {
        type: "error",
        text: json.ErrorMessage
      });

      return {
        IsSuccess: false
      }
    }

    return json;

  } catch (error) {
    console.error(error);
    Store.commit("NotificationAdd", {
      type: "error",
      text: error
    });

    return {
      IsSuccess: false
    }
  }
}
export async function TagCreate(name) {
  try {
    const request = {
      Name: name
    }
    let queryString = `?${serialize(request)}`;
    const response = await fetch(`${base}/api/tag/create${queryString}`, {
      method: "POST",
      mode: "cors", // no-cors, cors, *same-origin
      cache: "no-cache",
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        // "Content-Type": "application/json",
        "Accept": "application/json",
      },
      // redirect: "follow", // manual, *follow, error
      // referrer: "no-referrer", // no-referrer, *client
      // body: JSON.stringify(request),
    });
    let json = await response.json();

    if (!json.IsSuccess)
    {
      console.error(json);
      Store.commit("NotificationAdd", {
        type: "error",
        text: json.ErrorMessage
      });

      return {
        IsSuccess: false
      }
    }

    return json;

  } catch (error) {
    console.error(error);
    Store.commit("NotificationAdd", {
      type: "error",
      text: error
    });

    return {
      IsSuccess: false
    }
  }
}
export async function TagUpdate(id, name) {
  try {
    const request = {
      Id: parseInt(id),
      Name: name
    }
    const response = await fetch(`${base}/api/tag/update`, {
      method: "POST",
      mode: "cors", // no-cors, cors, *same-origin
      cache: "no-cache",
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
      // redirect: "follow", // manual, *follow, error
      // referrer: "no-referrer", // no-referrer, *client
      body: JSON.stringify(request),
    });
    let json = await response.json();

    if (!json.IsSuccess)
    {
      console.error(json);
      Store.commit("NotificationAdd", {
        type: "error",
        text: json.ErrorMessage
      });

      return {
        IsSuccess: false
      }
    }

    return json;

  } catch (error) {
    console.error(error);
    Store.commit("NotificationAdd", {
      type: "error",
      text: error
    });

    return {
      IsSuccess: false
    }
  }
}
export async function TagRemove(id) {
  try {
    const response = await fetch(`${base}/api/tag/remove/${id}`, {
      method: "POST",
      mode: "cors", // no-cors, cors, *same-origin
      cache: "no-cache",
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        // "Content-Type": "application/json",
        "Accept": "application/json",
      },
      // redirect: "follow", // manual, *follow, error
      // referrer: "no-referrer", // no-referrer, *client
      // body: JSON.stringify(request),
    });
    let json = await response.json();

    if (!json.IsSuccess)
    {
      console.error(json);
      Store.commit("NotificationAdd", {
        type: "error",
        text: json.ErrorMessage
      });

      return {
        IsSuccess: false
      }
    }

    return json;

  } catch (error) {
    console.error(error);
    Store.commit("NotificationAdd", {
      type: "error",
      text: error
    });

    return {
      IsSuccess: false
    }
  }
}

export async function AdvertGet(id) {
  try {
    const response = await fetch(`${base}/api/advert/get/${id}`, {
      method: "GET",
      mode: "cors", // no-cors, cors, *same-origin
      cache: "no-cache",
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        // "Content-Type": "application/json",
        "Accept": "application/json",
      },
      // redirect: "follow", // manual, *follow, error
      // referrer: "no-referrer", // no-referrer, *client
      // body: JSON.stringify(request),
    });
    let json = await response.json();

    if (!json.IsSuccess)
    {
      console.error(json);
      Store.commit("NotificationAdd", {
        type: "error",
        text: json.ErrorMessage
      });

      return {
        IsSuccess: false
      }
    }

    return json;

  } catch (error) {
    console.error(error);
    Store.commit("NotificationAdd", {
      type: "error",
      text: error
    });

    return {
      IsSuccess: false
    }
  }
}
export async function AdvertGetAll(fields) {
  try {
    const request = {
      Fields: fields
    }
    let queryString = `?${serialize(request)}`;
    const response = await fetch(`${base}/api/advert/get-all${queryString}`, {
      method: "GET",
      mode: "cors", // no-cors, cors, *same-origin
      cache: "no-cache",
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        // "Content-Type": "application/json",
        "Accept": "application/json",
      },
      // redirect: "follow", // manual, *follow, error
      // referrer: "no-referrer", // no-referrer, *client
      // body: JSON.stringify(request),
    });
    let json = await response.json();

    if (!json.IsSuccess)
    {
      console.error(json);
      Store.commit("NotificationAdd", {
        type: "error",
        text: json.ErrorMessage
      });

      return {
        IsSuccess: false
      }
    }

    return json;

  } catch (error) {
    console.error(error);
    Store.commit("NotificationAdd", {
      type: "error",
      text: error
    });

    return {
      IsSuccess: false
    }
  }
}
export async function AdvertCreate(name, script) {
  try {
    const request = {
      Name: name,
      Script: script
    }
    // let queryString = `?${serialize(request)}`;
    const response = await fetch(`${base}/api/advert/create`, {
      method: "POST",
      mode: "cors", // no-cors, cors, *same-origin
      cache: "no-cache",
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
      // redirect: "follow", // manual, *follow, error
      // referrer: "no-referrer", // no-referrer, *client
      body: JSON.stringify(request),
    });
    let json = await response.json();

    if (!json.IsSuccess)
    {
      console.error(json);
      Store.commit("NotificationAdd", {
        type: "error",
        text: json.ErrorMessage
      });

      return {
        IsSuccess: false
      }
    }

    return json;

  } catch (error) {
    console.error(error);
    Store.commit("NotificationAdd", {
      type: "error",
      text: error
    });

    return {
      IsSuccess: false
    }
  }
}
export async function AdvertUpdate(id, name, script) {
  try {
    const request = {
      Id: id,
      Name: name,
      Script: script
    }
    // let queryString = `?${serialize(request)}`;
    const response = await fetch(`${base}/api/advert/update`, {
      method: "POST",
      mode: "cors", // no-cors, cors, *same-origin
      cache: "no-cache",
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
      // redirect: "follow", // manual, *follow, error
      // referrer: "no-referrer", // no-referrer, *client
      body: JSON.stringify(request),
    });
    let json = await response.json();

    if (!json.IsSuccess)
    {
      console.error(json);
      Store.commit("NotificationAdd", {
        type: "error",
        text: json.ErrorMessage
      });

      return {
        IsSuccess: false
      }
    }

    return json;

  } catch (error) {
    console.error(error);
    Store.commit("NotificationAdd", {
      type: "error",
      text: error
    });

    return {
      IsSuccess: false
    }
  }
}
export async function AdvertEnable(id) {
  try {
    const response = await fetch(`${base}/api/advert/enable/${id}`, {
      method: "POST",
      mode: "cors", // no-cors, cors, *same-origin
      cache: "no-cache",
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        // "Content-Type": "application/json",
        "Accept": "application/json",
      },
      // redirect: "follow", // manual, *follow, error
      // referrer: "no-referrer", // no-referrer, *client
      // body: JSON.stringify(request),
    });
    let json = await response.json();

    if (!json.IsSuccess)
    {
      console.error(json);
      Store.commit("NotificationAdd", {
        type: "error",
        text: json.ErrorMessage
      });

      return {
        IsSuccess: false
      }
    }

    return json;

  } catch (error) {
    console.error(error);
    Store.commit("NotificationAdd", {
      type: "error",
      text: error
    });

    return {
      IsSuccess: false
    }
  }
}
export async function AdvertDisable(id) {
  try {
    const response = await fetch(`${base}/api/advert/disable/${id}`, {
      method: "POST",
      mode: "cors", // no-cors, cors, *same-origin
      cache: "no-cache",
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        // "Content-Type": "application/json",
        "Accept": "application/json",
      },
      // redirect: "follow", // manual, *follow, error
      // referrer: "no-referrer", // no-referrer, *client
      // body: JSON.stringify(request),
    });
    let json = await response.json();

    if (!json.IsSuccess)
    {
      console.error(json);
      Store.commit("NotificationAdd", {
        type: "error",
        text: json.ErrorMessage
      });

      return {
        IsSuccess: false
      }
    }

    return json;

  } catch (error) {
    console.error(error);
    Store.commit("NotificationAdd", {
      type: "error",
      text: error
    });

    return {
      IsSuccess: false
    }
  }
}
export async function AdvertRemove(id) {
  try {
    const response = await fetch(`${base}/api/advert/remove/${id}`, {
      method: "POST",
      mode: "cors", // no-cors, cors, *same-origin
      cache: "no-cache",
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        // "Content-Type": "application/json",
        "Accept": "application/json",
      },
      // redirect: "follow", // manual, *follow, error
      // referrer: "no-referrer", // no-referrer, *client
      // body: JSON.stringify(request),
    });
    let json = await response.json();

    if (!json.IsSuccess)
    {
      console.error(json);
      Store.commit("NotificationAdd", {
        type: "error",
        text: json.ErrorMessage
      });

      return {
        IsSuccess: false
      }
    }

    return json;

  } catch (error) {
    console.error(error);
    Store.commit("NotificationAdd", {
      type: "error",
      text: error
    });

    return {
      IsSuccess: false
    }
  }
}

export async function AnalyticsGet(id) {
  try {
    const response = await fetch(`${base}/api/analytics/get/${id}`, {
      method: "GET",
      mode: "cors", // no-cors, cors, *same-origin
      cache: "no-cache",
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        // "Content-Type": "application/json",
        "Accept": "application/json",
      },
      // redirect: "follow", // manual, *follow, error
      // referrer: "no-referrer", // no-referrer, *client
      // body: JSON.stringify(request),
    });
    let json = await response.json();

    if (!json.IsSuccess)
    {
      console.error(json);
      Store.commit("NotificationAdd", {
        type: "error",
        text: json.ErrorMessage
      });

      return {
        IsSuccess: false
      }
    }

    return json;

  } catch (error) {
    console.error(error);
    Store.commit("NotificationAdd", {
      type: "error",
      text: error
    });

    return {
      IsSuccess: false
    }
  }
}
export async function AnalyticsGetAll(fields) {
  try {
    const request = {
      Fields: fields
    }
    let queryString = `?${serialize(request)}`;
    const response = await fetch(`${base}/api/analytics/get-all${queryString}`, {
      method: "GET",
      mode: "cors", // no-cors, cors, *same-origin
      cache: "no-cache",
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        // "Content-Type": "application/json",
        "Accept": "application/json",
      },
      // redirect: "follow", // manual, *follow, error
      // referrer: "no-referrer", // no-referrer, *client
      // body: JSON.stringify(request),
    });
    let json = await response.json();

    if (!json.IsSuccess)
    {
      console.error(json);
      Store.commit("NotificationAdd", {
        type: "error",
        text: json.ErrorMessage
      });

      return {
        IsSuccess: false
      }
    }

    return json;

  } catch (error) {
    console.error(error);
    Store.commit("NotificationAdd", {
      type: "error",
      text: error
    });

    return {
      IsSuccess: false
    }
  }
}
export async function AnalyticsCreate(name, script) {
  try {
    const request = {
      Name: name,
      Script: script
    }
    // let queryString = `?${serialize(request)}`;
    const response = await fetch(`${base}/api/analytics/create`, {
      method: "POST",
      mode: "cors", // no-cors, cors, *same-origin
      cache: "no-cache",
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
      // redirect: "follow", // manual, *follow, error
      // referrer: "no-referrer", // no-referrer, *client
      body: JSON.stringify(request),
    });
    let json = await response.json();

    if (!json.IsSuccess)
    {
      console.error(json);
      Store.commit("NotificationAdd", {
        type: "error",
        text: json.ErrorMessage
      });

      return {
        IsSuccess: false
      }
    }

    return json;

  } catch (error) {
    console.error(error);
    Store.commit("NotificationAdd", {
      type: "error",
      text: error
    });

    return {
      IsSuccess: false
    }
  }
}
export async function AnalyticsUpdate(id, name, script) {
  try {
    const request = {
      Id: id,
      Name: name,
      Script: script
    }
    // let queryString = `?${serialize(request)}`;
    const response = await fetch(`${base}/api/analytics/update`, {
      method: "POST",
      mode: "cors", // no-cors, cors, *same-origin
      cache: "no-cache",
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
      // redirect: "follow", // manual, *follow, error
      // referrer: "no-referrer", // no-referrer, *client
      body: JSON.stringify(request),
    });
    let json = await response.json();

    if (!json.IsSuccess)
    {
      console.error(json);
      Store.commit("NotificationAdd", {
        type: "error",
        text: json.ErrorMessage
      });

      return {
        IsSuccess: false
      }
    }

    return json;

  } catch (error) {
    console.error(error);
    Store.commit("NotificationAdd", {
      type: "error",
      text: error
    });

    return {
      IsSuccess: false
    }
  }
}
export async function AnalyticsEnable(id) {
  try {
    const response = await fetch(`${base}/api/analytics/enable/${id}`, {
      method: "POST",
      mode: "cors", // no-cors, cors, *same-origin
      cache: "no-cache",
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        // "Content-Type": "application/json",
        "Accept": "application/json",
      },
      // redirect: "follow", // manual, *follow, error
      // referrer: "no-referrer", // no-referrer, *client
      // body: JSON.stringify(request),
    });
    let json = await response.json();

    if (!json.IsSuccess)
    {
      console.error(json);
      Store.commit("NotificationAdd", {
        type: "error",
        text: json.ErrorMessage
      });

      return {
        IsSuccess: false
      }
    }

    return json;

  } catch (error) {
    console.error(error);
    Store.commit("NotificationAdd", {
      type: "error",
      text: error
    });

    return {
      IsSuccess: false
    }
  }
}
export async function AnalyticsDisable(id) {
  try {
    const response = await fetch(`${base}/api/analytics/disable/${id}`, {
      method: "POST",
      mode: "cors", // no-cors, cors, *same-origin
      cache: "no-cache",
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        // "Content-Type": "application/json",
        "Accept": "application/json",
      },
      // redirect: "follow", // manual, *follow, error
      // referrer: "no-referrer", // no-referrer, *client
      // body: JSON.stringify(request),
    });
    let json = await response.json();

    if (!json.IsSuccess)
    {
      console.error(json);
      Store.commit("NotificationAdd", {
        type: "error",
        text: json.ErrorMessage
      });

      return {
        IsSuccess: false
      }
    }

    return json;

  } catch (error) {
    console.error(error);
    Store.commit("NotificationAdd", {
      type: "error",
      text: error
    });

    return {
      IsSuccess: false
    }
  }
}
export async function AnalyticsRemove(id) {
  try {
    const response = await fetch(`${base}/api/analytics/remove/${id}`, {
      method: "POST",
      mode: "cors", // no-cors, cors, *same-origin
      cache: "no-cache",
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        // "Content-Type": "application/json",
        "Accept": "application/json",
      },
      // redirect: "follow", // manual, *follow, error
      // referrer: "no-referrer", // no-referrer, *client
      // body: JSON.stringify(request),
    });
    let json = await response.json();

    if (!json.IsSuccess)
    {
      console.error(json);
      Store.commit("NotificationAdd", {
        type: "error",
        text: json.ErrorMessage
      });

      return {
        IsSuccess: false
      }
    }

    return json;

  } catch (error) {
    console.error(error);
    Store.commit("NotificationAdd", {
      type: "error",
      text: error
    });

    return {
      IsSuccess: false
    }
  }
}