<template>
  <div>
    <div class="container">
      <div class="row">
        <h2 class="col-12 my-3">Редактирование блока рекламы</h2>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label for="advert-name">Имя</label>
            <input type="text" class="form-control" id="advert-name" v-model="advert.Name"/>
          </div>
        </div>

        <div class="col-12">
          <div class="form-group">
            <label for="advert-script">Скрипт</label>
            <textarea
                class="form-control form-control-lg"
                id="advert-script"
                rows="3"
                v-model="advert.Script"
            ></textarea>
          </div>
        </div>

        <div class="col-12 mt-3">
          <button type="button" class="btn btn-primary" @click="save">Сохранить</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {AdvertGet, AdvertUpdate} from "@/api";

export default {
  name: "Advert-Update",
  components: {},
  props: ["id"],
  data: function () {
    return {
      advert: {},
    };
  },
  watch: {},
  created() {
    this.get();
  },
  computed: {},
  methods: {
    get: function () {
      AdvertGet(this.id)
      .then((data) => {
        this.advert = data.Document;
      })
      .catch((error) => console.error(error));
    },
    save: function () {
      AdvertUpdate(this.advert.Id, this.advert.Name, this.advert.Script)
      .then((data) => {
        if (data.IsSuccess) {
          this.$router.push({name: 'advert-list'});
        }
      })
      .catch((error) => console.error(error));
    },
  },
};
</script>

<style scoped lang="scss">
</style>