<template>
    <div>
        <Loader v-show="!IsLoaded"/>

        <div v-show="IsLoaded && !IsAuth">
            <span>Вы не авторизованны</span>
        </div>

        <div v-show="IsLoaded && IsAuth" id="main-container">
            <MenuTop class="app-menu-top"/>
            <MenuLeft class="app-menu-left"/>
            <router-view class="app-main-container"/>
        </div>

        <Notifications />
    </div>
</template>

<script>
import Store from "@/store";
// import EventBus from "@/event-bus";
import Loader from "@/components/Loader.vue";
import MenuLeft from "@/components/MenuLeft.vue";
import MenuTop from "@/components/MenuTop.vue";
import Notifications from "@/views/Notifications/Notifications.vue";

export default {
    name: "App-Main",
    components: {
        Loader,
        MenuLeft,
        MenuTop,
        Notifications
    },
    computed: {
        IsLoaded() {
            return Store.state.IsLoaded;
        },
        IsAuth() {
            return Store.state.IsAuth;
        },
    },
    created() {
        this.$store.dispatch("userRefresh");
    },
    methods: {},
};
</script>

<style lang="scss">
@import "~bootstrap";

* {
    margin: 0;
    padding: 0;
    outline: none;
    font-family: "Open Sans", sans-serif;
}

html,
body,
#main-container {
    height: 100%;
}

#main-container {
    display: grid;
    grid-template-columns: 180px 1fr;
    grid-template-rows: 40px 1fr;
    grid-template-areas: "menu-top menu-top" "menu-left main-container";

    .app-menu-top {
        grid-area: menu-top;

        position: fixed;

        width: 100%;

        z-index: 99999;
    }

    .app-menu-left {
        grid-area: menu-left;

        position: fixed;

        top: 40px;
        width: 180px;
        height: 100%;
    }

    .app-main-container {
        grid-area: main-container;
    }
}

.ck-sticky-panel__content_sticky {
    top: 40px !important;
}
</style>