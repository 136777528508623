<template>
    <div>
        <div class="container">
            <div class="row">
                <h2 class="col-12 my-3">Статьи</h2>

                <div class="col-8">
                    <div class="input-group mb-3">
                        <input type="text" class="form-control" placeholder="Поиск" v-model="query"/>
                        <!-- <div class="input-group-append"> -->
                        <!-- <button class="btn btn-primary" type="button">Поиск</button> -->
                        <!-- </div> -->
                    </div>

                    <div class="input-group mb-3">
                        <label class="input-group-text" for="inputGroupSelect02">Сортировать по</label>
                        <select class="form-select" id="inputGroupSelect02" v-model="sortField">
                            <option value="1">идентификатору</option>
                            <option value="2">дате создания</option>
                            <option value="3">дате редактирования</option>
                            <option value="4">заголовку</option>
                        </select>
                        <select class="form-select" id="inputGroupSelect01" v-model="sortDirection">
                            <option value="1">по возрастанию</option>
                            <option value="2">по убыванию</option>
                        </select>
                    </div>

                    <div class="form-check">
                        <input
                            class="form-check-input"
                            type="checkbox"
                            id="article-search-by-body"
                            v-model="byBody"
                        />
                        <label class="form-check-label" for="article-search-by-body">Поиск по содержанию</label>
                    </div>
                </div>
            </div>

            <br/>

            <div class="btn-toolbar" role="toolbar">
                <router-link to="/article-create" class="action">
                    <button type="button" class="btn btn-primary mr-3">
                        <i class="fas fa-plus-square"></i> Создать
                    </button>
                </router-link>

                <Paginate :total="pageMax" :selected="page" :callback="pageSelect"/>
            </div>

            <div class="my-4">
                <span>По запросу найдено {{ total }} страниц</span>
            </div>

            <br/>

            <table class="table table-hover">
                <thead>
                <tr>
                    <th>ID</th>
                    <th>Заголовок</th>
                    <!--          <th>Автор</th>-->
                    <th>Дата создания</th>
                    <th>Дата редактирования</th>
                    <!--          <th>-->
                    <!--            <i class="fas fa-eye"></i>-->
                    <!--          </th>-->
                    <th style="min-width: 105px;">Действия</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="article in pages" :key="article.Id">
                    <td>{{ article.Id }}</td>
                    <td>
                        <a :href="article.Link">{{ article.Title }}</a>
                    </td>
                    <!--          <td>{{ article.author }}</td>-->
                    <td>{{ dateFormat(article.CreatedAt) }}</td>
                    <td>{{ dateFormat(article.UpdatedAt) }}</td>
                    <!--          <td>{{ article.views }}</td>-->
                    <td>
                        <!-- <router-link :to="editPage(article.Id)" class="action"> -->
                        <router-link :to="{ name: 'article-edit', params: { id: article.Id }}" class="action">
                            <i class="fa fa-pencil"></i>
                        </router-link>
                        <button class="action" @click="copyCode(article.Id)"><i class="fad fa-copy"></i></button>
                        <!--            <router-link to="#" class="action">-->
                        <!--              <i class="fa fa-unlock"></i>-->
                        <!--            </router-link>-->
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
const {DateTime} = require("luxon");
import Paginate from "@/components/Paginate.vue";

import {PageSearch} from "@/api";

export default {
    name: "Article-List",
    components: {
        Paginate,
    },
    data: function () {
        return {
            query: "",

            sortField: 3,
            sortDirection: 2,

            byBody: false,

            itemsOnPage: 25,
            page: 1,
            pageMax: 1,

            pages: [],
            total: 0,
        };
    },
    watch: {
        query: function () {
            this.page = 1;
            this.debounceSearch();
        },
        sortField: function () {
            this.debounceSearch();
        },
        sortDirection: function () {
            this.debounceSearch();
        },
        itemsOnPage: function () {
            this.debounceSearch();
        },
        page: function () {
            this.search();
        },
        byBody: function () {
            this.page = 1;
            this.search();
        }
    },
    created() {
        this.search();
    },
    computed: {},
    methods: {
        copyCode(id) {
            navigator.clipboard.writeText(`page:${id}`)
        },
        dateFormat(unix) {
            return DateTime.fromSeconds(unix).toFormat("yyyy-MM-dd HH:mm:ss");
        },
        pageSelect(page) {
            this.page = Number.parseInt(page);
        },
        debounceSearch: function () {
            clearTimeout(this.debounce);
            this.debounce = setTimeout(() => {
                this.search();
            }, 600);
        },
        search: function () {
            PageSearch(
                this.query,
                this.page,
                this.itemsOnPage,
                this.sortField,
                this.sortDirection,
                this.byBody,
                "Id,Title,CreatedAt,UpdatedAt,Link"
            )
                .then((data) => {
                    this.pages = data.Document.Items;
                    this.total = data.Document.Total;
                    this.pageMax = Math.floor(this.total / this.itemsOnPage) + 1;
                    // console.log(data);
                    // if (!data.is_successed) {
                    //   Store.commit("ErrorAdd", data.error);
                    //   return;
                    // }
                    // this.rowsTable = data.document;
                })
                .catch((error) => console.error(error));
        },
    },
};
</script>

<style scoped lang="scss">
table {
    a {
        text-decoration: none;
        color: #000000;

        &:hover {
            text-decoration: underline;
        }
    }

    .action {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;

        padding: 5px;
        margin: 0 2px;
        height: 36px;
        width: 36px;

        border: 1px solid transparent;
        border-radius: 5px;

        color: #23282d;

        &:hover, &:focus {
            outline: none;
            color: #007bff;
            text-decoration: none;

            border: 1px solid #007bff;
        }
    }
}
</style>