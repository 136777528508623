import { createRouter, createWebHistory } from 'vue-router'

import Home from '@/views/Home.vue'

import ArticleCreate from '@/views/Article/Create.vue'
import ArticleList from '@/views/Article/List.vue'
import ArticleEdit from '@/views/Article/Edit.vue'

import MediaCreate from '@/views/Media/Create.vue'
import MediaList from '@/views/Media/List.vue'
import MediaEdit from '@/views/Media/Edit.vue'

import TagCreate from '@/views/Tag/Create.vue'
import TagList from '@/views/Tag/List.vue'
import TagEdit from '@/views/Tag/Edit.vue'

import AdvertCreate from '@/views/Advert/Create.vue'
import AdvertList from '@/views/Advert/List.vue'
import AdvertEdit from '@/views/Advert/Edit.vue'

import AnalyticsCreate from '@/views/Analytics/Create.vue'
import AnalyticsList from '@/views/Analytics/List.vue'
import AnalyticsEdit from '@/views/Analytics/Edit.vue'

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home
    },

    {
        path: '/article-create',
        name: 'article-create',
        component: ArticleCreate
    },
    {
        path: '/article-list',
        name: 'article-list',
        component: ArticleList
    },
    {
        path: '/article-edit/:id',
        name: 'article-edit',
        component: ArticleEdit,
        props: true
    },

    {
        path: '/media-create',
        name: 'media-create',
        component: MediaCreate
    },
    {
        path: '/media-list',
        name: 'media-list',
        component: MediaList
    },
    {
        path: '/media-edit/:id/',
        name: 'media-edit',
        component: MediaEdit,
        props: true
    },

    {
        path: '/tag-create',
        name: 'tag-create',
        component: TagCreate
    },
    {
        path: '/tag-list',
        name: 'tag-list',
        component: TagList
    },
    {
        path: '/tag-edit/:id/',
        name: 'tag-edit',
        component: TagEdit,
        props: true
    },

    {
        path: '/advert-create',
        name: 'advert-create',
        component: AdvertCreate
    },
    {
        path: '/advert-list',
        name: 'advert-list',
        component: AdvertList
    },
    {
        path: '/advert-edit/:id',
        name: 'advert-edit',
        component: AdvertEdit,
        props: true
    },

    {
        path: '/analytics-create',
        name: 'analytics-create',
        component: AnalyticsCreate
    },
    {
        path: '/analytics-list',
        name: 'analytics-list',
        component: AnalyticsList
    },
    {
        path: '/analytics-edit/:id',
        name: 'analytics-edit',
        component: AnalyticsEdit,
        props: true
    },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
