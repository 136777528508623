<template>
    <div class="loader">
        <div class="cube">
            <div class="front"></div>
            <div class="back"></div>
            <div class="top"></div>
            <div class="bottom"></div>
            <div class="left"></div>
            <div class="right"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: "App-Loader",
    data() {
        return {};
    },
    created() {
    },
    unmounted() {
    },
};
</script>

<style scoped lang="scss">
.loader {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    --size: 80px;
    --half-size: 40px;
    --m-half-size: -40px;

    background-color: white;
}

.cube {
    margin: auto;
    position: relative;
    height: var(--size);
    width: var(--size);
    transform-style: preserve-3d;
}

.cube div {
    position: absolute;
    height: 100%;
    width: 100%;
    // opacity: 0.9;
    background-image: url(../assets/quad.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.front {
    transform: translateZ(var(--half-size));
}

.back {
    transform: translateZ(var(--m-half-size)) rotateY(180deg);
}

.right {
    transform: rotateY(-270deg) translateX(var(--half-size));
    transform-origin: top right;
}

.left {
    transform: rotateY(270deg) translateX(var(--m-half-size));
    transform-origin: center left;
}

.top {
    transform: rotateX(-270deg) translateY(var(--m-half-size));
    transform-origin: top center;
}

.bottom {
    transform: rotateX(270deg) translateY(var(--half-size));
    transform-origin: bottom center;
}

@keyframes rotate {
    from {
        transform: rotateX(0deg) rotateY(0deg);
    }
    to {
        transform: rotateX(360deg) rotateY(360deg);
    }
}

.cube {
    animation: rotate 2s infinite linear;
}
</style>