<template>
  <div>
    <div class="container">
      <div class="row">
        <h2 class="col-12 my-3">Создание медиа</h2>

        <div class="col-8">
          <div class="form-group">
            <label for="article-title">Описание</label>
            <textarea
                class="form-control form-control-lg"
                id="article-title"
                rows="5"
                v-model="description"
                maxlength="256"
            ></textarea>
          </div>
        </div>

        <div class="col-8">
          <div class="input-file">
            <label class="label">
              <i class="fal fa-paperclip"></i>
              <span class="title">Загрузить файл</span>
              <input type="file" ref="file" v-on:change="handleFileUpload()"/>
            </label>
          </div>
        </div>

        <div class="col-6 my-2">
          <img :src="filePreview" class="image-preview"/>
        </div>

        <div class="col-12">
          <button type="button" class="btn btn-primary" @click="upload">Создать</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {MediaUpload} from "@/api";

export default {
  name: "Media-Create",
  components: {},
  data: function () {
    return {
      description: "",
      file: "",

      filePreview: null,
    };
  },
  computed: {},
  methods: {
    handleFileUpload() {
      this.file = this.$refs.file.files[0];

      this.filePreview = URL.createObjectURL(this.file);
    },
    upload: function () {
      MediaUpload(this.description, 1, this.file)
      .then((data) => {
        if (data.IsSuccess) {
          this.$router.push({name: 'media-list'});
        }
        // console.log(data);
        // if (!data.is_successed) {
        //   Store.commit("ErrorAdd", data.error);
        //   return;
        // }
        // this.rowsTable = data.document;
      })
      .catch((error) => console.error(error));
    },
  },
};
</script>

<style scoped lang="scss">
.image-preview {
  max-width: 300px;
  max-height: 300px;
}

.input-file {
  input[type="file"] {
    display: none;
  }

  .label {
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    border: 2px dashed grey;
    border-radius: 5px;
    padding: 1.2em;
    font-size: 20px;
    transition: border 300ms ease;
    cursor: pointer;
    text-align: center;

    &:hover {
      border: 2px solid #000;

      & > svg,
      & > .title {
        color: black;
      }
    }

    i {
      margin: 0 10px;
    }

    .title {
      color: grey;
      transition: 200ms color;
    }
  }
}
</style>