<template>
    <div>
        <div class="container">
            <div class="row">
                <h2 class="col-12 my-3">Список медиа</h2>

                <div class="col-8">
                    <div class="input-group mb-3">
                        <input type="text" class="form-control" placeholder="Поиск" v-model="query"/>
                        <!-- <div class="input-group-append">
                          <button class="btn btn-primary" type="button">Поиск</button>
                        </div>-->
                    </div>

                    <div class="input-group mb-3">
                        <label class="input-group-text" for="inputGroupSelect02">Сортировать по</label>
                        <select class="form-select" id="inputGroupSelect02" v-model="sortField">
                            <option value="1">идентификатору</option>
                            <option value="2">дате создания</option>
                            <option value="3">дате редактирования</option>
                            <option value="4">описанию</option>
                            <option value="5">MIME-типу</option>
                            <option value="6">расширению файла</option>
                            <option value="7">размеру файла</option>
                            <option value="8">типу медиа</option>
                        </select>
                        <select class="form-select" id="inputGroupSelect01" v-model="sortDirection">
                            <option value="1">по возрастанию</option>
                            <option value="2">по убыванию</option>
                        </select>
                    </div>
                </div>
            </div>

            <br/>

            <div class="btn-toolbar" role="toolbar">
                <router-link to="/media-create" class="action">
                    <button type="button" class="btn btn-primary mr-3">
                        <i class="fas fa-plus-square"></i> Создать
                    </button>
                </router-link>

                <Paginate :total="pageMax" :selected="page" :callback="pageSelect"/>

                <button type="button" class="btn btn-primary ml-3" @click="seeFull = !seeFull">
                    <i class="fas fa-expand-alt" v-show="seeFull"></i>
                    <i class="fas fa-compress-alt" v-show="!seeFull"></i>
                </button>
            </div>

            <div class="my-4">
                <span>По запросу найдено {{ total }} медиа</span>
            </div>
        </div>

        <div class="gallery">
            <div class="image-container" :class="{ 'see-full': seeFull }" v-for="(el, i) in media" :key="i">
                <img :src="el.Link"/>
                <div class="overlay">
                    <div class="id">Идентификатор: {{ el.ShortId }}</div>
                    <div>
                        <button class="btn btn-primary mr-2" @click="copyCode(el.ShortId)"><i class="fad fa-copy"></i>
                        </button>
                        <router-link :to="{ name: 'media-edit', params: { id: el.Id }}" class="btn btn-primary">
                            Изменить
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Paginate from "@/components/Paginate.vue";

import {MediaSearch} from "@/api";

export default {
    name: "Media-List",
    components: {
        Paginate,
    },
    data: function () {
        return {
            query: "",
            sortField: 3,
            sortDirection: 2,

            itemsOnPage: 100,
            page: 1,
            pageMax: 1,

            media: [],
            total: 0,

            seeFull: false
        };
    },
    watch: {
        query: function () {
            this.page = 1;
            this.debounceSearch();
        },
        sortField: function () {
            this.debounceSearch();
        },
        sortDirection: function () {
            this.debounceSearch();
        },
        itemsOnPage: function () {
            this.debounceSearch();
        },
        page: function () {
            this.search();
        },
    },
    created() {
        this.search();
    },
    computed: {},
    methods: {
        copyCode(id) {
            navigator.clipboard.writeText(`~img:${id}~`)
        },
        pageSelect(page) {
            this.page = Number.parseInt(page);
        },
        // getImgUrl(id) {
        //   return `${process.env.VUE_APP_API_BASE}/api/media/file/${id}`;
        // },
        debounceSearch: function () {
            clearTimeout(this.debounce);
            this.debounce = setTimeout(() => {
                this.search();
            }, 600);
        },
        search: function () {
            MediaSearch(
                this.query,
                this.page,
                this.itemsOnPage,
                this.sortField,
                this.sortDirection
            )
                .then((data) => {
                    this.media = data.Document.Items;
                    this.total = data.Document.Total;
                    this.pageMax = Math.floor(this.total / this.itemsOnPage) + 1;
                    // console.log(data);
                    // if (!data.is_successed) {
                    //   Store.commit("ErrorAdd", data.error);
                    //   return;
                    // }
                    // this.rowsTable = data.document;
                })
                .catch((error) => console.error(error));
        },
    },
};
</script>

<style scoped lang="scss">
// .paging > .btn:focus {
//   outline: none;
//   box-shadow: none;
//   background-color: #f8f9fa;
//   border: none;
// }

.gallery {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px 10px;

    padding: 20px;

    @media (min-width: 1200px) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    @media (min-width: 1400px) {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }

    div.image-container {
        position: relative;

        & > img {
            // max-width: 200px;
            object-fit: cover;
            height: 100%;
            width: 100%;

            max-height: 300px;
        }

        &.see-full {
            & > img {
                object-fit: scale-down;
            }
        }

        & > .overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: none;
            color: #fff;

            & > .id {
                margin: 10px 0;
            }

            & > button {
                margin: 10px 0;
            }
        }

        &:hover > .overlay {
            display: flex;
            background: rgba(0, 0, 0, 0.6);
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }
}
</style>