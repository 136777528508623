import {createStore} from 'vuex'

import * as api from "@/api";
import EventBus from "@/event-bus";

export default createStore({
    state: {
        IsLoaded: false,
        IsAuth: false,

        Id: "",
        Username: "",

        Providers: {
            VK: false,
        },

        Notifications: []
    },
    mutations: {
        IsLoaded(state) {
            state.IsLoaded = true;
            if (!state.IsLoaded) {
                EventBus.$emit('IsLoaded');
            }
        },
        userRefresh(state, payload) {
            state.IsAuth = payload.IsAuth;
            state.Id = payload.Id;
            state.Username = payload.Username;

            this.commit('IsLoaded', null);
            EventBus.$emit('userRefresh');
        },
        NotificationAdd(state, payload) {
            state.Notifications.push(payload);
        },
        NotificationRemove(state, id) {
            state.Notifications.splice(id, 1);
        }
    },
    actions: {
        async userProfile(context) {
            let profile = await api.Profile();

            context.commit('userRefresh', {
                IsAuth: profile.IsSuccess === true,
                Id: profile.Document?.Id,
                Username: profile.Document?.Username
            });
        },
        async userRefresh(context) {
            await context.dispatch("userProfile");
        }
    }
})
