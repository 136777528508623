<template>
  <div class="notifications">
    <div :class="e.type === 'error' ? 'error' : 'success'" v-for="(e, i) in Notifications" :key="i">
      <span>{{ e.text }}</span>
      <br />
      <button @click="remove(i)">Закрыть</button>
    </div>
  </div>
</template>

<script>
import Store from "@/store";

export default {
  name: "App-Notifications",
  data: function() {
    return {};
  },
  computed: {
    Notifications() {
      return Store.state.Notifications;
    }
  },
  methods: {
    remove: function(id) {
      Store.commit("NotificationRemove", id);
    }
  }
};
</script>

<style scoped lang="scss">
.notifications {
  position: fixed;
  bottom: 0;
  right: 0;

  & > div {
    margin: 10px;

    color: white;
    padding: 10px;
    border-radius: 10px;

    &.error {
      background-color: #ff2626;
    }
    &.success {
      background-color: #4dff7f;
    }
  }
}

</style>